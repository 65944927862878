import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TopHeader from "../../../components/top-header"

import { Row, Container, Col, } from 'react-bootstrap/'
import RealTime from "../../../images/real-time-monitoring.svg"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const GercekZaman = ({ intl }) => {
  const data = useStaticQuery(graphql`
  query {
    headerImage: file(relativePath: { eq: "modules/Analiz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return <Layout >
    <TopHeader />
    <SEO title={intl.formatMessage({ id: "solutions_table_module_19" })} />
    <Container>

      <div className='image-wrapper' fluid>
        <Img style={{ borderRadius: '1rem' }} alt="retmes izleme ve analiz" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title2__1" /></h2>
      </div>
      <h1 className='page-title'><FormattedMessage id="solutions_table_module_19" /></h1>
      <Row className='content-page-row'>
        <Col>
          <p className='content-page-p'>
            <FormattedMessage id="real_time_monitoring_desc_p" />
          </p>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={RealTime} style={{ width: '50%' }} alt="retmes gerçek zamanlı izleme" />
        </Col>
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(GercekZaman)